<template>
  <section v-if="data && data.length" class="h-100">
    <b-card :header="header" no-body>
      <b-row class="text-center mx-0">
        <b-col
          v-for="(c, i) in data"
          :key="'counts' + i"
          class="
            border-top border-right
            d-flex
            align-items-between
            flex-column
            py-2
          "
        >
          <b-card-text class="text-muted mb-0"> {{ c.label }} </b-card-text>
          <h3 class="font-weight-bolder mb-0">
            {{ c.value }}
          </h3>
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>

<script>
import { BCard, BCardHeader, BRow, BCol, BCardText } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardHeader,
    BRow,
    BCardText,
    BCol,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    header: {
      type: String,
      default: () => "Students",
    },
  },
  data() {
    return {};
  },
};
</script>
