<template>
  <b-card
    v-if="series && series.length"
    class="earnings-card"
    title="Applications"
  >
    <vue-apex-charts :options="getOptions()" :series="series" />
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BCardTitle, BCardText } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    VueApexCharts,
  },
  props: {
    series: {
      type: Array,
      default: () => [],
    },
    labels: {
      type: Array,
      default: () => [],
    },
  },
  watch() {},
  data() {
    return {};
  },
  methods: {
    getOptions() {
      const labels = this.labels;
      const colors = [
        "#472F05", //1 //
        "#4a0c04", //2 //
        "#871C0E", //3 //
        "#BB4E0B", //4 //
        "#FF5367", //5
        "#EC6816",//6  //
        "#F08315", //7 //
        "#FB8C00", //8
        "#FB8C00", //9 //
        "#FAA943", //10
        "#FF5367", //11
        "#97A3FF", //12 //
        "#FAA943", //13
        "#FFB74D", //14
        "#97A3FF", //15
        "#97A3FF", //16
      ];

      return {
        chart: {
          type: "donut",
          toolbar: {
            show: true,
          },
        },
        dataLabels: {
          enabled: true,
        },
        legend: { show: true },
        labels: labels,
        stroke: { width: 0 },
        colors: colors,
        grid: {
          padding: {
            right: -20,
            bottom: -8,
            left: -20,
          },
        },
        plotOptions: {
          pie: {
            startAngle: -10,
            donut: {
              labels: {
                show: true,
                name: {
                  offsetY: 15,
                },
                value: {
                  offsetY: -15,
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${parseInt(val)}`;
                  },
                },
                total: {
                  show: true,
                  offsetY: 15,
                },
              },
            },
          },
        },
        // responsive: [
        //   {
        //     breakpoint: 1325,
        //     options: {
        //       chart: {
        //         height: 100,
        //       },
        //     },
        //   },
        //   {
        //     breakpoint: 1200,
        //     options: {
        //       chart: {
        //         height: 120,
        //       },
        //     },
        //   },
        //   {
        //     breakpoint: 1045,
        //     options: {
        //       chart: {
        //         height: 100,
        //       },
        //     },
        //   },
        //   {
        //     breakpoint: 992,
        //     options: {
        //       chart: {
        //         height: 120,
        //       },
        //     },
        //   },
        // ],
      };
    },
  },
};
</script>
