<template>
  <e-charts
    ref="line"
    autoresize
    :options="line"
    theme="theme-color"
    auto-resize
    @click="(e) => getStudentsWithDateRange(e.name)"
  />
</template>

<script>
import ECharts from "vue-echarts";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/chart/line";
import theme from "./theme.json";
import moment from 'moment';

ECharts.registerTheme("theme-color", theme);

export default {
  components: {
    ECharts,
  },
  props: {
    optionData: {
      type: Object,
      default: null,
    },
  },
  methods: {
    getStudentsWithDateRange(dateRange) {
      const dates = dateRange.split(" to ");
      const startDate = dates[0];
      const endDate = dates[1];
      const dateRangeStr = `${moment(startDate, "DD-MMM-YYYY").format(
        "YYYY-MM-DD"
      )} to ${moment(endDate, "DD-MMM-YYYY").format("YYYY-MM-DD")}`;
      this.$router.push(`/student_list?dateRange=${dateRangeStr}`);
    },
  },
  computed: {
    line: function () {
      let line = {
        // Make gradient line here
        visualMap: [
          {
            show: true,
            type: "continuous",
            min: 0,
            max: 400,
          },
        ],
        grid: {
          width: "96%",
          left: "30px",
          top: "10px",
          show: false,
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: [
          {
            boundaryGap: false,
            data: this.optionData.xAxisData,
          },
        ],
        yAxis: {
          type: "value",
          splitLine: { show: false },
        },
        series: {
          type: "line",
          showSymbol: false,
          data: this.optionData.series,
        },
      };
      return line;
    },
  },
  // data() {
  //   return {
  //     line: {
  //       // Make gradient line here
  //       visualMap: [
  //         {
  //           show: true,
  //           type: "continuous",
  //           min: 0,
  //           max: 400,
  //         },
  //       ],
  //       grid: {
  //         width: "96%",
  //         left: "30px",
  //         top: "10px",
  //         show: false,
  //       },
  //       tooltip: {
  //         trigger: "axis",
  //       },
  //       xAxis: [
  //         {
  //           boundaryGap: false,
  //           data: this.optionData.xAxisData,
  //         },
  //       ],
  //       yAxis: {
  //         type: "value",
  //         splitLine: { show: false },
  //       },
  //       series: {
  //         type: "line",
  //         showSymbol: false,
  //         data: this.optionData.series,
  //       },
  //     },
  //   };
  // },
};
</script>

<style>
.echarts {
  width: 100% !important;
}
</style>
